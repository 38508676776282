@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

* {
  margin: 0px;
  font-family: "Quicksand";
  color: #333;


}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.alertConfirm {
  color: #ff9942;
  font-size: larger;
  background-color: transparent;
  padding: 10px;
}

.alertCancel {
  color: #2f5387;
  font-size: larger;
  background-color: transparent;
  font-size: larger;
  background-color: transparent;
  padding: 10px;
}

.loading-spinner {

  width: 25px;
  height: 25px;
  border: 3px solid #f3f3f3;
  /* Light grey */
  border-top: 3px solid #2f5387;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.container {

  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  align-self: center;
  margin: 0;


  flex: 1;
  background-color: white;
  margin: 0px;
  /* justify-content: center; */
  height: 80vh;
  margin-top: 10vh;
}


.questionContainer {

  display: flex;
  flex-direction: column;
  
  justify-content: space-around;
  justify-self: center;
  justify-items: space-between;
  align-items: space-between;
  align-content: space-between;
  align-self: space-between;
  margin: 0;


  flex: 1;
  background-color: white;
  margin: 0px;
  /* justify-content: center; */
  height: 50vh;
  margin-top: 0vh;
}

.tfContainer {
  display: flex;
  flex-direction: column;
  

}

.tfRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  
  margin: 10px;


}

.tfRow h3 {
  margin-left: 30px;
  margin-top: 10px;
}

.imageHolder {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  align-self: center;
}

.input {
  display: flex;
  width: 70vw;
  margin-top: 5vh;
  margin-bottom: 2vh;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  justify-items: center;
  align-items: center;
  align-content: center;
  align-self: center;
  border-width: 1px;
  border-radius: 5px;
  padding-left: 10px;

}


.navbar {

  padding: 10px;
  display: flex;
  align-items: center;
  /* width: 80%; */
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
  position: fixed;
  max-height: 20vh;
  background-color: white;
  z-index: 1000;
  top: 0px
}

.navbar h1 {
  color: #2f5387;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
}

.navbar .links {
  margin-left: auto;

}

.navbar a {
  margin-left: 0px;
  text-decoration: none;
  padding: 6px;
}

.navbar a:hover {
  color: coral;
}

.proc-list {
  margin-top: 10vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.list-preview {

  margin: 10px;
  border-bottom: 1px solid #cfcfcd;
  margin-bottom: 30px;
  padding: 10px;
}

.list-preview-highlight {

  margin: 10px;
  border-bottom: 1px solid #cfcfcd;
  margin-bottom: 30px;
  padding: 10px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  border-color: green;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
}

.list-preview:hover {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  border-color: #ff9942;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
}

.list-preview h2 {
  color: #2f5387;

}

.list-preview p {
  color: grey;

}

.content {
  max-width: 100vw;
  margin: 0px auto;
  padding: 20px;
  padding-top: 20%;
}

.listItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.listItem button {
  height: 80%;
  color: white;
  background-color: hsl(215, 48%, 36%);
  border-width: 0px;
  border-radius: 5px;

}

.bluelink {
  height: 80%;

  color: white;
  background-color: hsl(215, 48%, 36%);
  border-width: 0px;
  border-radius: 5px;
  padding: 10px;
  text-decoration: none;

}

.orangelink {
  height: 80%;
text-align: center;
  color: white;
  background-color: #ff9942;
  border-width: 0px;
  border-radius: 5px;
  padding: 10px;
  text-decoration: none;

}

.greylink {
  height: 80%;

  color: white;
  background-color: lightgray;
  border-width: 0px;
  border-radius: 5px;
  padding: 10px;
  text-decoration: none;

}

.topsection {
  margin-top: 10px;
}

.middlesection {
  margin-top: 10px;
}

.bottomsection {
  margin-top: 10px;
}

input[type=radio] {
  appearance: none;
 padding: 20px;
  background-color: white;
  border-radius:50%;
  border-color: black;
  border-style: solid;
  border-width: 2px;
  
}
input[type=radio]:checked {
 
  background: green;
}

/* .blueButton {
  color: white;
  background-color: #2f5387;
  padding: 5px;
 
  border-radius: 5px;
  border-width: 0px;
  
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30vw;
  margin-top: 20px;
  height: 40px;
  display: flex;
  margin-top: 10vh;
} */

.blueButton {
  color: white;
  background-color: #2f5387;
  padding: 5px;
 
  border-radius: 5px;
  border-width: 2px;
  border-color: #2f5387;
  border-style:solid ;
  
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  height: 40px;
  display: flex;
  margin-top: 10vh;
}
.greenButton {
  color: white;
  background-color: green;
  padding: 5px;
 
  border-radius: 5px;
  border-width: 0px;
  
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  height: 40px;
  display: flex;
  margin-top: 10vh;
}
.whiteButton {
  color: #2f5387;
  background-color: white;
  padding: 5px;
 
  border-radius: 5px;
  border-width: 2px;
  border-color: #2f5387;
  border-style:solid ;
  
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 20px;
  height: 40px;
  display: flex;
  margin-top: 10vh;
}

.anatomylabel {
  color: black;
  border-style: solid;
  background-color: white;
  border-color: grey;
  padding: 5px;
  margin-right: 20px;
  border-radius: 5px;
  border-width: 1px;
  justify-content: center;
  align-items: center;
  text-align: center;


  height: 40px;
  display: flex
}



.anatomylabel:hover {
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  border-color: #ff9942;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
}

.anatomyscreen {

  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: white;
  margin: 0px;
  /* justify-content: center; */
  height: 80vh;
  margin-top: 10vh;
  z-index: 1
}

.procedurescreen {

  display: flex;
  flex-direction: row;
  flex: 1;
  background-color: white;
  margin: 0px;
  /* justify-content: center; */
  align-items: center;
  height: calc(80% *6/7);


  z-index: 1
}

.procedureTypeButtonContainer {

  z-index: 1;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto;
  height: calc(80% *6/7);
  /* justify-content: space-between; */
}

.anatomybuttons {
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto;
  height: calc(80% *6/7);
  justify-content: space-between;

}

.anatomypic {

  display: flex;
  flex-direction: column;
  flex: 1;

  justify-content: center;
  height: 80vh;
  width: 100vw;
  z-index: 1
}



img {
  border-radius: 5px;
  align-self: center;
}

.columnLeft {

  margin: 10px
}

button {

  border: none;
  outline: none;
  cursor: pointer;
}

.on {
  color: #000;
}

.off {
  color: #ccc;
}